import type { Event, EventContent, EventItemsSelectionItem } from '../booking-utils/types';
import { getTicketPrice } from '../booking-utils/common.utils';
import {
	Types,
	WebTracking,
	EventTransformers,
	getContentNameForTracking,
	Fragments,
	booleanFilter
} from 'common';
import dayjs from 'dayjs';
import _uniq from 'lodash/uniq';
import { contentUtils } from '..';

export const transformPrintedCoursebookToProduct = (
	event: Event,
	participationType: Types.ParticipationType
): WebTracking.ProductAdded => ({
	...(event.content?.contentId ? { content_id: event.content.contentId } : undefined),
	brand: EventTransformers.transformEventFormatToContentBrand(event.eventFormat),
	category: 'COURSE',
	variant: participationType === Types.ParticipationType.Livestream ? 'ONLINE' : 'ON_SITE',
	sku: Types.TicketType.PrintedCourseBook,
	location: event.city?.value || '',
	price:
		typeof event.printedCourseBookPrice === 'number' ? event.printedCourseBookPrice / 100 : -1,
	specialties: [],
	main_speciality: event.productCategory.code,
	name: 'Printed Course Book'
});

export const transformEventItemToProduct = (
	event: Event,
	eventItem: EventItemsSelectionItem,
	content: EventContent | undefined,
	locale: Locale
): WebTracking.ProductAdded => {
	const startDateTime = dayjs(eventItem.startDateTime).toDate();
	const endDateTime = dayjs(eventItem.endDateTime).toDate();

	return {
		...(event.content?.contentId ? { content_id: event.content.contentId } : undefined),
		brand: EventTransformers.transformEventFormatToContentBrand(event.eventFormat),
		category: 'COURSE',
		variant:
			eventItem.participationType === Types.ParticipationType.Livestream
				? 'ONLINE'
				: 'ON_SITE',
		sku: eventItem.ticketType,
		location: event.city?.value || '',
		price: eventItem.price / 100,
		specialties: [],
		main_speciality: event.productCategory.code,
		...(content ? { name: getContentNameForTracking(content, locale) } : {}),
		start_datetime: startDateTime,
		end_datetime: endDateTime
	};
};

export const transformEventToProduct = (
	event: Event,
	content: EventContent,
	selectedItems: Array<EventItemsSelectionItem>,
	totalPrice: number,
	locale: Locale
): WebTracking.ProductAdded => {
	const selectedItem = selectedItems[0];
	return {
		...(event.content?.contentId ? { content_id: event.content.contentId } : undefined),
		brand: EventTransformers.transformEventFormatToContentBrand(event.eventFormat),
		category: 'COURSE',
		variant:
			selectedItem?.participationType === Types.ParticipationType.Livestream
				? 'ONLINE'
				: 'ON_SITE',
		sku: selectedItem?.ticketType || '',
		location: event.city?.value || '',
		price: totalPrice / 100,

		// hard coding it because we don't have specialities data on BookingEventR
		specialties: [],
		main_speciality: event.productCategory.code,
		name: getContentNameForTracking(content, locale)
	};
};

export const transformSelectedItemToProduct = (
	event: Event,
	selectedItem: EventItemsSelectionItem,
	content: EventContent | undefined,
	locale: Locale
): WebTracking.ProductAdded => {
	const startDateTime = dayjs(selectedItem.startDateTime).toDate();
	const endDateTime = dayjs(selectedItem.endDateTime).toDate();

	return {
		...(event.content?.contentId ? { content_id: event.content.contentId } : undefined),
		brand: EventTransformers.transformEventFormatToContentBrand(event.eventFormat),
		category: 'COURSE',
		variant:
			selectedItem.participationType === Types.ParticipationType.Livestream
				? 'ONLINE'
				: 'ON_SITE',
		sku: selectedItem.ticketType,
		location: event.city?.value || '',
		price: getTicketPrice(selectedItem) / 100,

		// hard coding it because we don't have specialities data on BookingEventR
		specialties: [],
		main_speciality: event.productCategory.code,
		...(content ? { name: getContentNameForTracking(content, locale) } : undefined),
		end_datetime: endDateTime,
		start_datetime: startDateTime
	};
};

export const transformToOrderStarted = ({
	event,
	totalPrice,
	currency,
	category,
	main_speciality,
	products
}: {
	event?: Event;
	products: Array<WebTracking.OrderCompletedProduct>;
	currency: Types.Currency;
	totalPrice: number;
	main_speciality?: string;
	category: WebTracking.CategoryElement;
}): WebTracking.OrderStarted => ({
	...(event
		? { brand: EventTransformers.transformEventFormatToContentBrand(event.eventFormat) }
		: undefined),
	category,
	main_speciality,
	total: totalPrice / 100,
	currency,
	products
});

export const transformToCompletedOrder = ({
	event,
	bookingId,
	category,
	clientReference,
	coupon,
	currency,
	discount,
	locale,
	main_speciality,
	products,
	specialities,
	tax,
	totalPrice
}: {
	event?: Event;
	bookingId: string;
	category: WebTracking.CategoryElement;
	clientReference: string;
	coupon?: string;
	currency: Types.Currency;
	discount?: number;
	locale: Locale;
	main_speciality?: string | undefined;
	products: Array<WebTracking.OrderCompletedProduct>;
	specialities?: Array<string>;
	tax?: number;
	totalPrice: number;
}): WebTracking.OrderCompleted => ({
	...(event
		? { brand: EventTransformers.transformEventFormatToContentBrand(event.eventFormat) }
		: undefined),
	category,
	coupon,
	currency,
	discount: typeof discount === 'number' ? discount / 100 : discount,
	locale,
	main_speciality,
	order_id: bookingId,
	products,
	specialities,
	tax,
	total: totalPrice / 100,
	transaction_id: clientReference
});

export const transformUserForSignUpStep = (
	user: Pick<
		Fragments.UserFieldsFragment,
		'email' | 'firstName' | 'lastName' | 'specialities' | 'addresses' | 'id'
	>,
	step: WebTracking.SignupStepCompletedStep
): WebTracking.SignupStepCompleted => ({
	step
});

export const transformContentDocumentsToProductList = (
	contentsForAnalytics: Array<Fragments.ContentForAnalyticsFieldsFragment>,
	listId: string | undefined,
	locale: Locale
): WebTracking.ProductListViewed => {
	const products = contentsForAnalytics.map((contentForAnalytics, index) => {
		const { specialties, ...product } = transformContentDocumentToProduct(contentForAnalytics, {
			locale,
			index
		});
		return {
			...product,
			position: index + 1
		};
	});
	const productsCategories = _uniq(products.map((product) => product.category));
	const productsBrands = _uniq(products.map((product) => product.brand)).filter(booleanFilter);
	return {
		list_id: listId,
		brands: productsBrands,
		categories: productsCategories,
		products
	};
};

export const transformContentDocumentToProduct = (
	contentForAnalytics: Fragments.ContentForAnalyticsFieldsFragment,
	params: {
		locale: Locale;
		index: number | undefined;
	}
): WebTracking.ProductAdded => {
	const { parent } = contentForAnalytics;
	const { locale, index } = params;
	const content = {
		id: contentForAnalytics.id,
		contentId: contentForAnalytics.contentId,
		brand: contentForAnalytics.brand || parent?.brand,
		city: contentForAnalytics.city || parent?.city,
		contentType: contentForAnalytics.contentType || parent?.contentType,
		specialities: contentForAnalytics.specialities || parent?.specialities,
		product: contentForAnalytics.product || parent?.product,
		title: contentForAnalytics.title || parent?.title,
		partners: (contentForAnalytics.partnerships || parent?.partnerships || [])
			.map((partnership) => partnership.partnerCompany)
			.filter(booleanFilter),
		startDateTime: parent?.startDateTime || contentForAnalytics.startDateTime
	};

	const city = content.city as Record<Locale, string> | null;

	const isVideo = contentUtils.isVideoContent(content);
	const isDocument = contentUtils.isDocumentContent(content);

	const brand = content.brand || 'UPDATE_REFRESHER';
	const category = (() => {
		if (isDocument) {
			return 'DOCUMENT';
		} else if (isVideo) {
			return 'VIDEO';
		} else if (content.contentType === Types.ContentType.Webinar) {
			return 'WEBINAR';
		} else {
			return 'COURSE';
		}
	})();

	return {
		content_id: content.contentId,
		brand: brand,
		category,
		location: (city && city[locale]) || '',
		// setting it to -1 since we don't really need the price for content viewed or content added events, only for events on booking page
		price: -1,
		variant: 'HYBRID',
		specialties: content.specialities,
		main_speciality: content.product?.code || '',
		name: getContentNameForTracking(content, locale),
		has_partnership: content.partners.length > 0,
		partnerships: content.partners.map((partner) => ({
			partner_ub_account_code: partner.ubAccountCode || undefined,
			partner_name: partner.name || undefined
		})),
		...(typeof index === 'number' ? { position: index + 1 } : undefined)
	};
};

export const transformCourseDocumentToProduct = (
	{ parent, ...courseForAnalytics }: Fragments.CourseForAnalyticsFieldsFragment,
	params: {
		locale: Locale;
		index: number | undefined;
	}
): WebTracking.ProductAdded => {
	const partnerships = [] as Fragments.ContentForAnalyticsFieldsFragment['partnerships'];
	const courseToContent = {
		...courseForAnalytics,
		partnerships,
		...(parent
			? {
					parent: {
						...parent,
						partnerships
					}
			  }
			: undefined)
	};

	return transformContentDocumentToProduct(courseToContent, params);
};

export type PlaybackTrackingParams = {
	contentId: string;
	contentIds: Array<string>;
	// playback position in seconds
	playbackPosition: number;
	playbackProgress: number;
	sessionId: Types.UUID;
	// value from 0 to 1
	volume: number;
	duration: number;
};

export const transformToPlayback = (
	params: PlaybackTrackingParams,
	isPlaybackStartedEvent: boolean
): Pick<WebTracking.VideoPlaybackStarted, 'video_playback'> => {
	const {
		contentId,
		contentIds,
		playbackPosition,
		playbackProgress,
		sessionId,
		volume,
		duration
	} = params;
	return {
		video_playback: {
			...(isPlaybackStartedEvent
				? { content_asset_ids: contentIds }
				: { content_asset_id: contentId }),
			position: Math.round(playbackPosition),
			session_id: sessionId,
			sound: volume * 100,
			total_length: Math.round(duration),
			progress: playbackProgress
		}
	};
};
